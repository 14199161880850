<template>
  <div class="goodsdetailebox">
    <div class="headerbox">
    </div>
    <div class="center">
      <div class="imglist">
        <el-carousel :interval="4000" height="6.12rem" arrow="always" indicator-position="none" @change="imgchange" ref="carousel" @setActiveItem="setActiveItem">
          <el-carousel-item height="5.12rem" v-for="item,index in goodsdata.images" :key="index">
            <img :src="onlineUrl+item" alt="">
          </el-carousel-item>
        </el-carousel>
        <div class="simgbox">
          <div class="simgitem point" :class="{'acimgitem':acimg===index}" v-for="item,index in goodsdata.images" @click="imgchangeto(index)" :key="index">
            <img class="simg" :src="onlineUrl+item" alt="">
          </div>
        </div>
      </div>
      <div class="titlebox">
        <p>{{goodsdata.title}}</p>
      </div>
      <div class="textinfo" v-html="goodsdata.content"></div>
      <div class="btnbox">
        <div class="btn point" @click="tocontact">Contact Us</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getgoods_detailApi } from '../../network/api';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      goodsdata: [],
      goodsid: null,
      acimg: 0,
    };
  },
  computed: {
    ...mapState(["onlineUrl"]),
  },
  created() {
    this.goodsid = this.$route.query.id;
    this.getdata();
  },
  methods: {
    tocontact() {
      this.$router.push(
        {
          path: "/ContactUs",
        }
      );
    },
    setActiveItem(v) {
      this.$refs.carousel.setActiveItem(v);
    },
    imgchangeto(v) {
      if (this.acimg === v) {
        return;
      }
      this.acimg = v;
      this.setActiveItem(v);
    },
    imgchange(v) {
      this.acimg = v;
    },
    async getdata() {
      let params = {
        goods_id: this.goodsid
      };
      const res = await getgoods_detailApi(params);
      this.goodsdata = res.data;
    }
  }

}
</script>

<style lang="scss" scoped>
.goodsdetailebox {
  overflow: hidden;
  .headerbox {
    width: 19.2rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/images/all/pc-Product-title2.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0.6rem;
    .centertext {
      width: 6.97rem;
      height: 1.65rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.6rem;
      font-family: Paytone One, Paytone One;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.9);
      position: relative;
      .line {
        width: 3rem;
        height: 0.02rem;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
      }
      .line2 {
        width: 3rem;
        height: 0.02rem;
        background: #fff;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  .center {
    width: 13rem;
    margin: 0 auto;
    .imglist {
      width: 100%;
      height: 7.9rem;
      margin-bottom: 0.24rem;
      ::v-deep .el-carousel__arrow {
        width: 0.5rem;
        height: 0.5rem;
        font-size: 0;
        top: 55%;
        background-color: transparent;
        ::v-deep .el-icon-arrow-right:before {
          content: none;
        }
        ::v-deep .el-icon-arrow-left:before {
          content: none;
        }
      }
      ::v-deep .el-carousel__arrow--left {
        background: url("../../assets/images/all/pc-productd-l.png") no-repeat !important;
        background-size: 100% 100% !important;
      }
      ::v-deep .el-carousel__arrow--right {
        background: url("../../assets/images/all/pc-productd-r.png") no-repeat !important;
        background-size: 100% 100% !important;
      }

      .el-carousel__item img {
        height: 100%;
        display: block;
        margin: 0 auto;
      }
      .simgbox {
        margin-top: 0.24rem;
        width: 100%;
        background: #f8f6f4;
        display: flex;
        // overflow-x: scroll;
        .simgitem {
          width: 1.75rem;
          height: 1.54rem;
          margin: 0.06rem;
          flex-shrink: 0;
          .simg {
            width: 100%;
            height: 100%;
          }
        }
        .acimgitem {
          border: 0.02rem solid #29eba4;
          margin: 0.04rem;
        }
      }
    }
    .titlebox {
      width: 100%;
      height: 0.5rem;
      border-bottom: 0.02rem solid #eef5ee;
      margin-bottom: 0.3rem;
      p {
        font-size: 0.34rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 0.4rem;
      }
    }
    .textinfo {
      font-size: 0.28rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #acacac;
      line-height: 0.33rem;
    }
    .btnbox {
      margin-top: 0.6rem;
      width: 100%;
      height: 0.7rem;
      margin-bottom: 1.5rem;
      .btn {
        width: 5.6rem;
        height: 0.7rem;
        border-radius: 0.06rem;
        background: #005e3c;
        margin: 0 auto;
        font-size: 0.28rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.7rem;
        text-align: center;
      }
    }
  }
}
</style>